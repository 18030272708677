import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  Accordion,
  DataDisplay,
  FieldCheckbox,
  FieldRadioButtonGroup,
  FieldTextInput,
  Heading,
  PrimaryButton,
} from '../../components';
import Form from '../../components/Form/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import api from '../../api';
import classNames from 'classnames';

import css from './ListingPage.module.css';
import IconArrow from '../../components/IconArrow/IconArrow';
import { PENDING_INTAKE_STATUS } from '../../constants/listing';

const SectionCustomerManagement = props => {
  const { listing } = props;

  const { metadata } = listing.attributes;
  const {
    leaseExpirationOption,
    hasContactedLeasingUser,
    expirationOptionComment,
    dateLeasingUserContacted,
    dateLeaseExpirationOptionSet,
    leaseExpirationOptionComment,
  } = metadata;

  const intl = useIntl();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [decisionInProgress, setDecisionInProgress] = useState(false);

  if (!listing) return null;

  const onSubmit = async values => {
    try {
      setSubmitInProgress(true);
      setSubmitError(null);

      await api.listings.updateListing(listing.id.uuid, {
        metadata: {
          hasContactedLeasingUser: values.hasContactedLeasingUser,
          expirationOptionComment: values.expirationOptionComment,
          dateLeasingUserContacted: new Date(),
        },
      });
    } catch (error) {
      setSubmitError(error);
      console.error(error);
    } finally {
      setSubmitInProgress(false);
      window.location.reload();
    }
  };

  const onSubmitLeaseExpirationOption = async values => {
    try {
      setDecisionInProgress(true);
      const body = {
        leaseExpirationOption: values?.leaseExpirationOption,
      };

      await api.listings.setContractExpirationOptions(listing.id.uuid, body);

      await api.listings.updateListing(listing.id.uuid, {
        metadata: {
          dateLeaseExpirationOptionSet: new Date(),
          leaseExpirationOptionComment: values.leaseExpirationOptionComment,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDecisionInProgress(false);
      window.location.reload();
    }
  };

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.customerManagement" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          <li>
            <div className={css.leaseStatusLabels}>
              <label>
                {hasContactedLeasingUser
                  ? intl.formatMessage({ id: 'General.userContacted' })
                  : intl.formatMessage({ id: 'General.notContacted' })}
              </label>
              <IconArrow />
              <label
                className={classNames({
                  [css.activeLeaseStatusLabel]: hasContactedLeasingUser && !leaseExpirationOption,
                })}
              >
                <FormattedMessage id="General.inNegotiation" />
              </label>
              <IconArrow />
              <label
                className={classNames({ [css.activeLeaseStatusLabel]: leaseExpirationOption })}
              >
                <FormattedMessage id="General.decisionMade" />
              </label>
            </div>
          </li>
          <li>
            {hasContactedLeasingUser ? (
              <div className={css.contactedLeasingUser}>
                <div>
                  <DataDisplay
                    className={css.mainInfo}
                    label={intl.formatMessage({ id: 'General.hasContactedLeasingUser' })}
                  >
                    <IconArrow />
                  </DataDisplay>

                  {dateLeasingUserContacted &&
                    intl.formatDate(new Date(dateLeasingUserContacted), {
                      month: 'short',
                      year: 'numeric',
                      day: 'numeric',
                    })}
                </div>

                {expirationOptionComment && <span>&quot;{expirationOptionComment}&quot;</span>}
              </div>
            ) : (
              <FinalForm
                onSubmit={onSubmit}
                initialValues={{ hasContactedLeasingUser: false, expirationOptionComment: '' }}
                render={formProps => {
                  const { handleSubmit, values } = formProps;
                  return (
                    <Form onSubmit={handleSubmit} className={css.form}>
                      <FieldCheckbox
                        name="hasContactedLeasingUser"
                        id="hasContactedLeasingUser"
                        value={String(values?.hasContactedLeasingUser || hasContactedLeasingUser)}
                        label={intl.formatMessage({
                          id: 'ContractLeaseActions.hasContactedLeasingUser',
                        })}
                      />

                      {(values?.hasContactedLeasingUser || hasContactedLeasingUser) && (
                        <FieldTextInput
                          name="expirationOptionComment"
                          id="expirationOptionComment"
                          type="text"
                          className={css.commentField}
                          label={intl.formatMessage({
                            id: 'ContractLeaseActions.expirationOptionComment',
                          })}
                        />
                      )}

                      {submitError && (
                        <p className={css.error}>{intl.formatMessage({ id: 'General.error' })}</p>
                      )}

                      {(values?.hasContactedLeasingUser || hasContactedLeasingUser) && (
                        <PrimaryButton
                          type="submit"
                          disabled={
                            submitInProgress ||
                            !values?.hasContactedLeasingUser ||
                            hasContactedLeasingUser
                          }
                          inProgress={submitInProgress}
                          className={css.submit}
                        >
                          {intl.formatMessage({ id: 'General.confirm' })}
                        </PrimaryButton>
                      )}
                    </Form>
                  );
                }}
              />
            )}
          </li>

          {hasContactedLeasingUser && !leaseExpirationOption && (
            <li className={css.makeADecision}>
              <DataDisplay
                className={css.mainInfo}
                label={intl.formatMessage({ id: 'General.makeADecision' })}
              >
                <IconArrow />
              </DataDisplay>
              <FinalForm
                onSubmit={onSubmitLeaseExpirationOption}
                initialValues={{ hasContactedLeasingUser: false, expirationOptionComment: '' }}
                render={formProps => {
                  const { handleSubmit, values } = formProps;
                  return (
                    <Form onSubmit={handleSubmit} className={css.form}>
                      <FieldRadioButtonGroup
                        className={css.decisionButtons}
                        name="leaseExpirationOption"
                        options={Object.values(PENDING_INTAKE_STATUS).map(option => {
                          return {
                            name: option,
                            value: option,
                            label: intl.formatMessage({ id: `General.${option}` }),
                          };
                        })}
                      />

                      <FieldTextInput
                        name="leaseExpirationOptionComment"
                        id="leaseExpirationOptionComment"
                        type="text"
                        className={css.commentField}
                        label={intl.formatMessage({
                          id: 'ContractLeaseActions.expirationOptionComment',
                        })}
                      />

                      <PrimaryButton
                        type="submit"
                        disabled={decisionInProgress || !values.leaseExpirationOption}
                      >
                        <FormattedMessage id="General.confirmDecision" />
                      </PrimaryButton>
                    </Form>
                  );
                }}
              />
            </li>
          )}

          {hasContactedLeasingUser && leaseExpirationOption && (
            <li className={css.contactedLeasingUser}>
              <div>
                <DataDisplay
                  className={css.mainInfo}
                  label={intl.formatMessage({ id: `General.${leaseExpirationOption}.set` })}
                >
                  <IconArrow />
                </DataDisplay>

                {dateLeaseExpirationOptionSet &&
                  intl.formatDate(new Date(dateLeaseExpirationOptionSet), {
                    month: 'short',
                    year: 'numeric',
                    day: 'numeric',
                  })}
              </div>

              {leaseExpirationOptionComment && (
                <span>&quot;{leaseExpirationOptionComment}&quot;</span>
              )}
            </li>
          )}
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionCustomerManagement;
