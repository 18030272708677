import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix } = props;
  const images = listing.images;

  const { schemaImageUrls, dimrProductImageStudio } = listing.attributes?.publicData || {};

  const imageVariants = [
    'scaled-small',
    'scaled-medium',
    'scaled-large',
    'scaled-xlarge',
    'listing-card',
    'listing-card-2x',
  ];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  return (
    <ListingImageGallery
      rootClassName={css.productGallery}
      images={images}
      imageVariants={imageVariants}
      thumbnailVariants={thumbnailVariants}
      schemaImageUrls={schemaImageUrls || dimrProductImageStudio}
    />
  );
};

export default SectionGallery;
