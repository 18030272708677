import React from 'react';
import { Accordion, DataDisplay, Heading } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { extractInfoFromSerialNumber } from '../../util/listing';
import { formatMoney, subUnitsToMoney } from '../../util/currency';
import { useConfiguration } from '../../context/configurationContext';

import css from './ListingPage.module.css';

const RELEVANT_DIMR_FIELDS = [
  'dimrModel',
  'dimrProductLifetime',
  'dimrProductNameShort',
  'dimrProductDescription',
  'dimrWirelessConnectivity',
  'dimrWorkingAreaCapacity',
  'dimrBatteryCapacity',
  'dimrBatteryType',
  'dimrBatteryVoltage',
  'dimrCuttingHeightAdjustment',
  'dimrCuttingHeightMax',
  'dimrCuttingHeightMin',
  'dimrCuttingWidth',
];

const SectionContractProductDetails = props => {
  const { listing } = props;

  const intl = useIntl();
  const config = useConfiguration();

  if (!listing) return null;

  const { publicData } = listing.attributes;

  const { pnc, serialNumber, articleNumber, residualValue } = publicData || {};

  const dimrFields = Object.entries(publicData || {}).filter(
    entry =>
      entry[0].startsWith('dimr') &&
      typeof entry[1] !== 'object' &&
      RELEVANT_DIMR_FIELDS.includes(entry[0])
  );

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.productDetails" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.pnc' })}>
              {pnc || articleNumber || '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.modelYear' })}>
              {extractInfoFromSerialNumber(serialNumber)?.modelYear || '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.serialNumber' })}>
              {serialNumber || '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.residualValue' })}>
              {residualValue
                ? formatMoney(intl, subUnitsToMoney(residualValue, config.currency))
                : '-'}
            </DataDisplay>
          </li>

          {dimrFields?.map((field, i) => {
            const [name, value] = field;
            return (
              <li key={i} className={css.detailsRow}>
                <DataDisplay
                  label={intl.formatMessage(
                    { id: `General.${name}` },
                    { sup: str => <sup>{str}</sup> }
                  )}
                >
                  {value || '-'}
                </DataDisplay>
              </li>
            );
          })}
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionContractProductDetails;
