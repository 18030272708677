import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrow.module.css';

const DIRECTION_RIGHT = 'right';
const DIRECTION_LEFT = 'left';
const DIRECTION_DOWN = 'down';
const DIRECTION_UP = 'up';
const SIZE_BIG = 'big';
const SIZE_SMALL = 'small';

const IconArrow = props => {
  const { className, rootClassName, direction, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isRight = direction === DIRECTION_RIGHT;
  const isLeft = direction === DIRECTION_LEFT;
  const isDown = direction === DIRECTION_DOWN;
  const isUp = direction === DIRECTION_UP;
  const isBig = size === SIZE_BIG;
  const isSmall = size === SIZE_SMALL;

  if (isRight && isSmall) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.097.293a.975.975 0 0 1 1.3-.083l.094.083L27.997 14 14.49 27.707a.975.975 0 0 1-1.394 0 1.01 1.01 0 0 1-.082-1.32l.082-.094L25.21 14 13.097 1.707a1.01 1.01 0 0 1-.082-1.32z"
          fill="#3D3D3C"
        />
        <path
          d="M25.619 13c.544 0 .985.448.985 1 0 .513-.38.935-.87.993l-.115.007H.985A.993.993 0 0 1 0 14c0-.513.38-.935.87-.993L.985 13z"
          fill="#3D3D3C"
        />
      </svg>
    );
  } else if (isLeft && isSmall) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M14.899 27.707a.975.975 0 0 1-1.3.083l-.094-.083L0 14 13.505.293a.975.975 0 0 1 1.394 0c.355.36.382.928.082 1.32l-.082.094L2.787 14l12.112 12.293c.355.36.382.928.082 1.32z" />
          <path d="M2.377 15a.993.993 0 0 1-.985-1c0-.513.38-.935.87-.993L2.377 13h24.634c.544 0 .985.448.985 1 0 .513-.38.935-.87.993L27.01 15z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M28 28H0V0h28z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (isDown && isSmall) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M27.707 12.879a1 1 0 0 1 .083 1.32l-.083.094-13 13a1 1 0 0 1-1.414 0l-13-13a1 1 0 0 1 1.32-1.497l.094.083L14 25.17l12.293-12.292a1 1 0 0 1 1.32-.083z" />
          <path d="M15 26a1 1 0 0 1-1.993.117L13 26V1a1 1 0 0 1 1.993-.117L15 1z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h28v28H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (isUp && isSmall) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M.293 15.121a1 1 0 0 1-.083-1.32l.083-.094 13-13a1 1 0 0 1 1.414 0l13 13a1 1 0 0 1-1.32 1.497l-.094-.083L14 2.83 1.707 15.121a1 1 0 0 1-1.32.083z" />
          <path d="M13 2a1 1 0 0 1 1.993-.117L15 2v25a1 1 0 0 1-1.993.117L13 27z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M28 28H0V0h28z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (isRight && isBig) {
    return (
      <svg
        className={classes}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.097.293a.975.975 0 0 1 1.3-.083l.094.083L27.997 14 14.49 27.707a.975.975 0 0 1-1.394 0 1.01 1.01 0 0 1-.082-1.32l.082-.094L25.21 14 13.097 1.707a1.01 1.01 0 0 1-.082-1.32z"
          fill="#3D3D3C"
        />
        <path
          d="M25.619 13c.544 0 .985.448.985 1 0 .513-.38.935-.87.993l-.115.007H.985A.993.993 0 0 1 0 14c0-.513.38-.935.87-.993L.985 13z"
          fill="#3D3D3C"
        />
      </svg>
    );
  } else if (isLeft && isBig) {
    return (
      <svg
        className={classes}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M14.899 27.707a.975.975 0 0 1-1.3.083l-.094-.083L0 14 13.505.293a.975.975 0 0 1 1.394 0c.355.36.382.928.082 1.32l-.082.094L2.787 14l12.112 12.293c.355.36.382.928.082 1.32z" />
          <path d="M2.377 15a.993.993 0 0 1-.985-1c0-.513.38-.935.87-.993L2.377 13h24.634c.544 0 .985.448.985 1 0 .513-.38.935-.87.993L27.01 15z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M28 28H0V0h28z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (isDown && isBig) {
    return (
      <svg
        className={classes}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M27.707 12.879a1 1 0 0 1 .083 1.32l-.083.094-13 13a1 1 0 0 1-1.414 0l-13-13a1 1 0 0 1 1.32-1.497l.094.083L14 25.17l12.293-12.292a1 1 0 0 1 1.32-.083z" />
          <path d="M15 26a1 1 0 0 1-1.993.117L13 26V1a1 1 0 0 1 1.993-.117L15 1z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h28v28H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (isUp && isBig) {
    return (
      <svg
        className={classes}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#3D3D3C">
          <path d="M.293 15.121a1 1 0 0 1-.083-1.32l.083-.094 13-13a1 1 0 0 1 1.414 0l13 13a1 1 0 0 1-1.32 1.497l-.094-.083L14 2.83 1.707 15.121a1 1 0 0 1-1.32.083z" />
          <path d="M13 2a1 1 0 0 1 1.993-.117L15 2v25a1 1 0 0 1-1.993.117L13 27z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M28 28H0V0h28z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};

IconArrow.defaultProps = {
  className: null,
  rootClassName: null,
  direction: DIRECTION_RIGHT,
  size: SIZE_SMALL,
};

IconArrow.propTypes = {
  className: string,
  rootClassName: string,
  direction: oneOf([DIRECTION_RIGHT, DIRECTION_LEFT, DIRECTION_DOWN, DIRECTION_UP]).isRequired,
  size: oneOf([SIZE_BIG, SIZE_SMALL]),
};

export default IconArrow;
