import React from 'react';
import { Avatar, NamedLink } from '../../../components';
import { propTypes } from '../../../util/types';
import { bool, string } from 'prop-types';
import { richText } from '../../../util/richText';

import css from './ActivityFeed.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const Message = props => {
  const { message, formattedDate, showDisplayName, disableProfileLink } = props;

  return (
    <div className={css.message}>
      <Avatar
        className={css.avatar}
        user={message.sender}
        disableProfileLink={disableProfileLink}
      />
      <div>
        {showDisplayName && (
          <p className={css.displayName}>
            {disableProfileLink ? (
              message.sender?.attributes?.profile?.displayName
            ) : (
              <NamedLink
                {...(message.sender.id
                  ? { name: 'ProfilePage', params: { id: message.sender.id.uuid } }
                  : { name: 'ProfileBasePage' })}
              >
                {message.sender?.attributes?.profile?.displayName}
              </NamedLink>
            )}
          </p>
        )}
        <p className={css.messageContent}>
          {richText(message.attributes.content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkClass: css.messageLink,
            linkify: true,
          })}
        </p>
        <p className={css.messageDate}>{formattedDate}</p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
  showDisplayName: bool,
};

export default Message;
