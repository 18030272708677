import React from 'react';
import { Accordion, DataDisplay, Heading } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';

import css from './ListingPage.module.css';

const SectionContractDetails = props => {
  const { listing } = props;

  const intl = useIntl();

  if (!listing) return null;

  const { publicData, metadata } = listing.attributes;

  const { contractId, contractStartDate, contractEndDate } = publicData || {};

  const {
    contractEndOwnerId,
    contractEndOwnerFirstName,
    contractEndOwnerLastName,
    stContractEndOwnerId,
    contractEndOwnerCompanyName,
    sellingDealerFirstName,
    sellingDealerLastName,
    stSellingDealerId,
  } = metadata || {};

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.contractDetails" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          <li className={css.detailsRow}>
            <DataDisplay
              className={css.field}
              label={intl.formatMessage({ id: 'General.contractId' })}
            >
              {contractId}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'BookingDatesForm.bookingStartTitle' })}>
              {contractStartDate &&
                intl.formatDate(contractStartDate.date, {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                })}
            </DataDisplay>
            <DataDisplay label={intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' })}>
              {contractEndDate &&
                intl.formatDate(contractEndDate.date, {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                })}
            </DataDisplay>
          </li>

          <li>
            <DataDisplay label={intl.formatMessage({ id: 'General.sellingDealer' })}>
              {stSellingDealerId ? `${sellingDealerFirstName} ${sellingDealerLastName}` : '-'}
            </DataDisplay>
          </li>
          <li>
            <DataDisplay label={intl.formatMessage({ id: 'General.contractEndOwner' })}>
              {stContractEndOwnerId
                ? contractEndOwnerCompanyName ||
                  `${contractEndOwnerFirstName} ${contractEndOwnerLastName}`
                : contractEndOwnerId}
            </DataDisplay>
          </li>
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionContractDetails;
