import React, { useCallback, useState } from 'react';
import { formatDateWithProximity } from '../../../util/dates';
import { useIntl } from 'react-intl';
import Confirm from '../../../modules/confirm';
import api from '../../../api';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
import { useDispatch } from 'react-redux';
import { types as sdkTypes } from '../../../util/sdkLoader';
import OwnMessage from '../../TransactionPage/ActivityFeed/OwnMessage';
import Message from '../../TransactionPage/ActivityFeed/Message';
import { IconDelete, IconSpinner, LoadMoreWithKey, SecondaryButton } from '../../../components';
import { isMarketplaceSecondLife } from '../../../util/marketplace';
import { useConfiguration } from '../../../context/configurationContext';

import css from './CommentSection.module.css';

const { UUID } = sdkTypes;

const Comments = props => {
  const {
    comments,
    currentUser,
    listingId,
    onDeleteSuccess,
    commentsPagination,
    disabled,
    isFetchingComments,
    loadMoreComments,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const config = useConfiguration();

  const [isDeletingComment, setIsDeletingComment] = useState(false);

  const todayString = intl.formatMessage({ id: 'TransactionPage.ActivityFeed.today' });

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const deleteComment = useCallback(
    async commentId => {
      const shouldDelete = await Confirm({
        title: intl.formatMessage({ id: 'CommentSection.confirmDeleteTitle' }),
        confirmationText: intl.formatMessage({ id: 'General.delete' }),
        cancelText: intl.formatMessage({ id: 'General.cancel' }),
        onManageDisableScrolling,
      });

      if (!shouldDelete) return;

      try {
        setIsDeletingComment(true);

        await api.listings.deleteComment(listingId, commentId);

        onDeleteSuccess(commentId);
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeletingComment(false);
      }
    },
    [intl, listingId, onDeleteSuccess, onManageDisableScrolling]
  );

  return (
    <div>
      {comments?.length ? (
        <ul>
          {comments.map(commentObj => {
            const { _id, comment, createdAt, user: sender } = commentObj;

            const createdAtDate = new Date(createdAt);

            const formattedDate = formatDateWithProximity(createdAtDate, intl, todayString);

            const isOwnMessage = currentUser?.id?.uuid === sender?.id?.uuid;

            const content = {
              message: {
                id: new UUID(_id),
                type: 'message',
                sender,
                attributes: {
                  createdAt: createdAtDate,
                  content: comment,
                },
              },
              formattedDate,
            };

            return (
              <li key={_id}>
                {isOwnMessage ? (
                  <OwnMessage
                    {...content}
                    youMsg={intl.formatMessage({ id: 'General.you' }) + ' - '}
                  />
                ) : (
                  <Message
                    {...content}
                    showDisplayName
                    disableProfileLink={isMarketplaceSecondLife(config)}
                  />
                )}

                {isOwnMessage && (
                  <SecondaryButton
                    type="button"
                    disabled={disabled || isDeletingComment}
                    onClick={() => deleteComment(_id)}
                    inProgress={isDeletingComment}
                    className={css.deleteComment}
                    spinnerClassName={css.deleteCommentSpinner}
                  >
                    <IconDelete />
                  </SecondaryButton>
                )}
              </li>
            );
          })}
        </ul>
      ) : null}
      {isFetchingComments && (
        <div className={css.loadSpinner}>
          <IconSpinner />
        </div>
      )}
      {commentsPagination && (
        <LoadMoreWithKey
          pagination={commentsPagination}
          inProgress={isFetchingComments}
          loadMoreFn={loadMoreComments}
          className={css.loadMore}
          loadedItemsCount={comments?.length}
        />
      )}
    </div>
  );
};

export default Comments;
