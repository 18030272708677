import React from 'react';
import { Accordion, DataDisplay, Heading } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { canShowMarkAsSold, getListingStatusHistory } from '../../util/listing';
import { formatDateIntoPartials } from '../../util/dates';

import css from './ListingPage.module.css';

const SectionContractStatusHistory = props => {
  const { listing, currentUser } = props;

  const intl = useIntl();

  if (!listing) return null;

  const statusHistory = getListingStatusHistory(listing);

  // `canShowMarkAsSold` fulfills the conditions needed for showing statuses;
  // Other users need to see only the first (published) status.
  const statusHistoryFinal = canShowMarkAsSold(currentUser, listing)
    ? statusHistory
    : statusHistory[0];

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.statusHistory" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          {statusHistoryFinal?.length ? (
            statusHistoryFinal.map(({ at, status }) => (
              <li className={css.detailsRow} key={at}>
                <DataDisplay label={intl.formatMessage({ id: `General.${status}` })} row>
                  {formatDateIntoPartials(new Date(at), intl).dateAndTime}
                </DataDisplay>
              </li>
            ))
          ) : (
            <li className={css.detailsRow}>
              {intl.formatMessage({ id: 'General.noStatusHistory' })}
            </li>
          )}
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionContractStatusHistory;
