import React, { useEffect, useState, useRef } from 'react';
import { Accordion, DataDisplay, ExternalLink, Heading } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext';

import css from './ListingPage.module.css';
import api from '../../api';

const SectionContractRefurbishDetails = props => {
  const { listing } = props;

  const intl = useIntl();
  const config = useConfiguration();
  const [fetchFileInProgress, setFetchFileInProgress] = useState({
    autocheckReportAfterRefurbish: false,
    serviceChecklist: false,
  });
  const [fileUrls, setFileUrls] = useState({
    autocheckReportAfterRefurbish: null,
    serviceChecklist: null,
  });

  const numOfRetries = useRef(6);

  const listingUUID = listing?.id?.uuid;

  useEffect(() => {
    if (!listingUUID) return;

    const getContractFile = (listingId, fileType) => {
      setFetchFileInProgress(old => ({ ...old, [fileType]: true }));

      return api.listings
        .getListingPresignedUrl(listingId, fileType, 'get')
        .then(res => {
          const url = res.data.presignedUrl;

          setFileUrls(old => ({ ...old, [fileType]: url }));

          if (url === null && numOfRetries.current > 0) {
            setTimeout(() => {
              getContractFile(listingUUID, fileType);

              numOfRetries.current = numOfRetries.current - 1;
            }, 2000);
          }
        })
        .catch(e => {})
        .finally(() => {
          setFetchFileInProgress(old => ({ ...old, [fileType]: false }));
        });
    };

    getContractFile(listingUUID, 'autocheckReportAfterRefurbish');
    getContractFile(listingUUID, 'serviceChecklist');
  }, [listingUUID]);

  if (!listing) return null;

  const { publicData } = listing.attributes;

  const { refurbishDetails, outputGrading } = publicData || {};

  const listingFields = config.listing.listingFields;

  const outputGradingOptions =
    listingFields.find(field => field.key === 'outputGrading').enumOptions || [];

  const outputGradingLabelId = outputGradingOptions.find(o => o.option === outputGrading)?.label;

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.refurbishmentDetails" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          <li className={css.detailsRow}>
            <DataDisplay className={css.field}>{refurbishDetails || '-'}</DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.outputGrade' })}>
              {outputGradingLabelId ? intl.formatMessage({ id: outputGradingLabelId }) : '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay
              label={intl.formatMessage({ id: 'General.autocheckReportAfterRefurbish' })}
            >
              {fetchFileInProgress.autocheckReportAfterRefurbish ? (
                intl.formatMessage({ id: 'SearchFiltersMobile.loadingResults' })
              ) : fileUrls.autocheckReportAfterRefurbish ? (
                <ExternalLink href={fileUrls.autocheckReportAfterRefurbish} className={css.link}>
                  {intl.formatMessage({ id: 'General.viewFile' })}
                </ExternalLink>
              ) : (
                '-'
              )}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.serviceChecklist' })}>
              {fetchFileInProgress.serviceChecklist ? (
                intl.formatMessage({ id: 'SearchFiltersMobile.loadingResults' })
              ) : fileUrls.serviceChecklist ? (
                <ExternalLink href={fileUrls.serviceChecklist} className={css.link}>
                  {intl.formatMessage({ id: 'General.viewFile' })}
                </ExternalLink>
              ) : (
                '-'
              )}
            </DataDisplay>
          </li>
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionContractRefurbishDetails;
