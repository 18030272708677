import React, { useEffect, useState, useRef } from 'react';
import { Accordion, DataDisplay, ExternalLink, Heading } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext';

import css from './ListingPage.module.css';
import api from '../../api';

const SectionContractReviewDetails = props => {
  const { listing } = props;

  const intl = useIntl();
  const config = useConfiguration();
  const [fetchFileInProgress, setFetchFileInProgress] = useState(false);
  const [autocheckReportUrl, setAutocheckReportUrl] = useState(null);

  const numOfRetries = useRef(3);

  const listingUUID = listing?.id?.uuid;

  useEffect(() => {
    if (!listingUUID) return;

    const getContractFile = listingId => {
      setFetchFileInProgress(true);

      return api.listings
        .getListingPresignedUrl(listingId, 'autocheckReport', 'get')
        .then(res => {
          const url = res.data.presignedUrl;

          setAutocheckReportUrl(url);

          if (url === null && numOfRetries.current > 0) {
            setTimeout(() => {
              getContractFile(listingUUID);

              numOfRetries.current = numOfRetries.current - 1;
            }, 2000);
          }
        })
        .catch(e => {})
        .finally(() => {
          setFetchFileInProgress(false);
        });
    };

    getContractFile(listingUUID);
  }, [listingUUID]);

  if (!listing) return null;

  const { publicData } = listing.attributes;

  const { inputGrading, runningHours, reviewDetails, productServiced } = publicData || {};

  const listingFields = config.listing.listingFields;

  const inputGradingOptions =
    listingFields.find(field => field.key === 'inputGrading').enumOptions || [];

  const inputGradingLabelId = inputGradingOptions.find(o => o.option === inputGrading)?.label;

  return (
    <div className={css.sectionDetails}>
      <Accordion
        items={[
          {
            title: (
              <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="General.reviewDetails" />
              </Heading>
            ),
            content: '',
          },
        ]}
      >
        <ul className={css.details}>
          <li className={css.detailsRow}>
            <DataDisplay className={css.field}>{reviewDetails || '-'}</DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.inputGrade' })}>
              {inputGradingLabelId ? intl.formatMessage({ id: inputGradingLabelId }) : '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.runningHours' })}>
              {runningHours || '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.productServiced' })}>
              {productServiced ? intl.formatMessage({ id: `General.${productServiced}` }) : '-'}
            </DataDisplay>
          </li>

          <li className={css.detailsRow}>
            <DataDisplay label={intl.formatMessage({ id: 'General.autocheckReport' })}>
              {fetchFileInProgress ? (
                intl.formatMessage({ id: 'SearchFiltersMobile.loadingResults' })
              ) : autocheckReportUrl ? (
                <ExternalLink href={autocheckReportUrl} className={css.link}>
                  {intl.formatMessage({ id: 'General.viewFile' })}
                </ExternalLink>
              ) : (
                '-'
              )}
            </DataDisplay>
          </li>
        </ul>
      </Accordion>
    </div>
  );
};

export default SectionContractReviewDetails;
